import React from "react";

import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <title>Seite existiert nicht</title>
      <Link to="/">Zurück zur Startseite</Link>.
    </main>
  );
};

export default NotFoundPage;
